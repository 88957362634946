import styled, { createGlobalStyle, keyframes } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200&display=swap');
  * {
        box-sizing: border-box;
        padding: 0px;
        margin: 0px;
        font-family: 'Poppins', sans-serif;
    }
    body {
        background-color: ${({ theme }) => theme.body};
        color: ${({ theme }) => theme.text};
        transition: background-color 0.2s ease-in, color 0.2s ease-in;
        font-family:'Poppins', sans-serif;
    }

`;
export const Container = styled.div`
  width: 100%;
  padding: 0px 80px;
  @media (max-width: 968px) {
    padding: 10px 50px;
  }
  @media (max-width: 868px) {
    padding: 0px 30px;
  }
  @media (max-width: 568px) {
    padding: 0px 20px;
  }
`;

// body: "#0E071D",
// text: "#F6FAEF",
// #002e5b
// 003366
// white:#F6FAEF
// blue: #131B4E
// #0E071D
// blue #1e7898

export const lightTheme = {
  body: "#f1f1f1",
  text: "#070B1D",
};

export const darkTheme = {
  body: "#070B1D",
  text: "#f1f1f1",
};

export const HomeLink = styled.a`
  position: fixed;
  right: 30px;
  bottom: 30px;
  padding: 8px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify: center;
  border: 2px solid ${({ theme }) => (theme === "dark" ? "#f1f1f1" : "#121620")};
  background: ${({ theme }) => (theme === "dark" ? "#121620" : "#f1f1f1")};
  color: ${({ theme }) => (theme === "dark" ? "#f1f1f1" : "#121620")};
  transition: all 1.5s;
  opacity: 0;
  z-index: 1000;
  &.active {
    opacity: 1;
  }
`;

const spinner = keyframes`
  to {
    transform: rotate(360deg);
  }
`;
export const LoadingStyled = styled.div`
  width: 75px;
  height: 75px;
  border: 10px solid
    ${({ theme }) => (theme === "dark" ? "#0e071d" : "#c9c9c9")};
  border-radius: 50%;
  border-top-color: ${({ theme }) =>
    theme === "dark" ? "#c9c9c9" : "#0e071d"};
  animation: ${spinner} 1s linear infinite;

  @media (max-width: 568px) {
    width: 50px;
    height: 50px;
    border: 8px solid #c9c9c9;
    border-top-color: #0e071d;
  }
`;

export const WrapperFlexDiv = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? direction : "")};
  align-items: ${({ algitems }) => (algitems ? algitems : "")};
  justify-content: ${({ justifycontent }) =>
    justifycontent ? justifycontent : ""};
  gap: ${({ gap }) => (gap ? gap : "")};
  flex-wrap: ${({ wrap }) => (wrap ? wrap : "")};
  margin: ${({ mr }) => (mr ? mr : "")}
  width: 100%;
  @media (max-width: 768px) {
    flex-wrap: ${({ wrap }) => (wrap ? wrap : "")};
  }
`;
