import React from "react";
import styled from "styled-components";
import { LoadingStyled } from "../../GlobalStyles";

const LoadingDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Loading = () => {
  return (
    <LoadingDiv>
      <LoadingStyled />
    </LoadingDiv>
  );
};

export default Loading;
