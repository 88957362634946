import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import i18n from "./App/i18n/config";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Loading from "./App/Components/Loading/Loading";
import { I18nextProvider } from "react-i18next";

const App = React.lazy(() => import("./App/App"));
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <I18nextProvider i18n={i18n}>
    <Suspense fallback={<Loading />}>
      <Router>
        <Routes>
          <Route path="*" element={<App />} />
        </Routes>
      </Router>
    </Suspense>
  </I18nextProvider>
);
